import { createContext, useEffect, useState } from "react";
import jwtDecode from 'jwt-decode'

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

    const [currentUser, setCurrentUser] = useState(
        JSON.parse(localStorage.getItem("profile")) || null
    );

    const login = (user) => {
        setCurrentUser({user})
    };

    const logout = () => {
        setCurrentUser(null)
    };

    const verifyToken = (min) => {
        const duration = min * 1000;
        if (currentUser) {
            const decodedToken = JSON.parse(atob(currentUser.user.token.split(".")[1]));
            if (decodedToken.exp * duration < Date.now()) {
                return false;
            }
            return true;
        }
    };

    useEffect(() => {
        localStorage.setItem("profile", JSON.stringify(currentUser));
    }, [currentUser]);

    return (
        <AuthContext.Provider value={{ currentUser, login, logout, verifyToken }}>
            {children}
        </AuthContext.Provider>
    );
};