import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import useAuth from '../hooks/useAuth'

import Avatar from './Avatar'
import { AuthContext } from '../context/authContext'

import MenuIcon from '@mui/icons-material/Menu';

import logoImg from '../Images/sBlog-logo.jpg'

const LogoImage = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 5px;
`

const Container = styled.div`
    background-color: #2997ff;
    color: white;
    width: 100%;
    padding: 10px 0 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Left = styled.div`
    padding-left: 20px;
`

const Center = styled.div``

const Right = styled.div`
    padding-right: 20px;
    display: flex;
    align-items: center;

    @media (max-width: 576px) {
        flex-direction: column-reverse;
        align-items: end;
    }
`

const HamburgerButton = styled.button`
    margin: 0 10px;
    padding: 6px 6px 2px 6px;
    border: 1px solid #4ea9ff;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.3s ease;
    display: none;

    &:hover {
        background-color: #4ea9ff;
        color: white;
    };

    @media (max-width: 576px) {
        display: inline;
    }
`

const MenuList = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;

    @media (max-width: 576px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: end;
        display: ${props => props.show ? 'flex' : 'none'};
    }
`

const MenuItem = styled.li`
    margin: 3px 10px;
    text-transform: uppercase;
`

const Linka = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    color: white;
    &:hover {
        text-decoration: underline;
    }
`

export const Navbar = () => {

    const {currentUser} = useContext(AuthContext)
    const { isAdmin } = useAuth()
    const [toggleMenu, setToggleMenu] = useState(false)

    const handleToggleMenu = () => {
        setToggleMenu(!toggleMenu)
    }

    return (
    <Container>
        <Left>
            <Linka to="/">
                <LogoImage src={logoImg} alt='logo'/>
            </Linka>
        </Left>
        <Center></Center>

        <Right>
            <MenuList show={toggleMenu}>
                {isAdmin && <MenuItem><Linka to='/panel'>Panel</Linka></MenuItem>}

                <MenuItem><Linka to='/'>Strona główna</Linka></MenuItem>
                
                {currentUser ? (
                <>
                    <MenuItem>
                        <Linka to={'/add'} state={{edit: false, title: '', body: '', tags: ''}}>
                            Dodaj artykuł
                        </Linka>
                    </MenuItem>
                    <MenuItem>
                        <Linka to={'/myarticles'}>
                            Moje artykuły
                        </Linka>
                    </MenuItem>
                </>
                ) : (
                <>
                    <MenuItem><Linka to='/login'>Zaloguj się</Linka></MenuItem>
                </>
                )}
            </MenuList>
            <div style={{display: "flex"}}>
                <HamburgerButton onClick={handleToggleMenu}>
                    <MenuIcon/>
                </HamburgerButton>
                {currentUser && <Avatar />}
            </div>
        </Right>
    </Container>
  )
}

export default Navbar