import React from 'react'
import { BasicContainer, Header4 } from '../styles/GlobalStyles'
import gitLabImg from '../Images/gitlab.png'
import styled from 'styled-components'

const Ahref = styled.a`
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: #474747;
    &:hover {
        text-decoration: underline;
        color: black;
    }
`
const GitlabImage = styled.img`
  width: 25px;
  margin: 8px;
`

const UsefullLinks = () => {
  return (
    <BasicContainer>
        <Header4>Przydatne linki</Header4>
        <Ahref href='https://gitlab.rbcloud.pl/' target="_blank">
          <GitlabImage src={gitLabImg} alt='GitLab'/>
          gitlab.rbcloud.pl
        </Ahref>
    </BasicContainer>
  )
}

export default UsefullLinks
