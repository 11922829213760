import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getRandom } from './Home'

import axios from 'axios'

import { ColumnContainer, Left, Right } from '../styles/GlobalStyles'

import Navbar from '../components/Navbar'
import ArticleSearch from '../components/ArticleSearch'
import Newestarticles from '../components/NewestArticles'
import Randomarticles from '../components/RandomArticles'
import Footer from '../components/Footer'
import Article from '../components/Article'
import NotExists from '../components/NotExists'

import { BASE_URL } from "../baseurl";
import UsefullLinks from '../components/UsefullLinks'

export const ShowArticle = () => {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      });

    const {id} = useParams()
    const [article, setArticle] = useState([])
    const [articleNotExist, setArticleNotExist] = useState(false)

    useEffect(() =>{
        const fetchArticle = async () =>{
            try {
                await axios.get(`${BASE_URL}/api/v1/article/${id}`)
                .then(function (response) {
                    setArticle(response?.data)
                  })
            } catch(err) {
                err.response.status === 404 && setArticleNotExist(true)
            }
        }
        fetchArticle()

        return () => {
            setArticleNotExist(false)
        }
    }, [id])

    const [articles, setArticles] = useState([])

    useEffect(() =>{
        const fetchAllArticles = async () =>{
            try {
                await axios.get(`${BASE_URL}/api/v1/articles`)
                .then(function (response) {
                    setArticles(response?.data)
                  })
            } catch(err) {
                console.log(err)
            }
        }
        fetchAllArticles()
    }, [])

    var lastArticles = []
    var randomArticles = []
    if (articles.length > 0) {
        lastArticles = articles.slice(0, 5);
        randomArticles = getRandom(articles, articles.length<5 ? articles.length : 5)
    }

  return (
    <>
        <Navbar />
        <ColumnContainer>
            <Left>
                {!articleNotExist ? <Article article={article} noOfArticles={articles.length}/>
                : <NotExists/>}
            </Left>
            <Right>
                <ArticleSearch/>
                <Newestarticles lastArticles={lastArticles}/>
                <Randomarticles randomArticles={randomArticles}/>
                <UsefullLinks/>
            </Right>
        </ColumnContainer>
        <Footer/>
    </>
  )
}

export default ShowArticle